import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/system/styleFunctionSx';
import Divider from '@mui/material/Divider';
import { Paper } from 'components/Paper';

interface SimpleCardProps {
  title?: string;
  children: React.ReactNode;
  sx?: SxProps;
}

export const SimpleCard = ({
  title,
  children,
  sx,
}: SimpleCardProps): JSX.Element => {
  return (
    <Paper variant="outlined" sx={sx}>
      {title !== undefined && (
        <Box padding={4} paddingBottom={0}>
          <Box paddingBottom={2}>
            <Typography variant="h6">{title}</Typography>
          </Box>
          <Divider />
        </Box>
      )}
      <Box padding={4}>{children}</Box>
    </Paper>
  );
};
