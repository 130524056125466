import { ReactNode } from 'react';
import { PermissionGatedContent } from 'components/PermissionGatedContent';
import { PermissionsEnum } from 'model/User';
import { GatedContent } from 'components/GatedContent';
import { AppLoadingMessage } from 'components/AppLoadingMessage';
import useUserV2 from 'hooks/useUserV2';
import { useRouter } from 'next/router';
import { ActiveFlag } from 'hooks/unleashHooks';

interface Props {
  permissionsNeeded: PermissionsEnum;
  children: ReactNode;
  unleashFlag?: ActiveFlag;
}

export const GrantAccess = ({
  children,
  unleashFlag,
  permissionsNeeded,
}: Props): JSX.Element => {
  const { data, error, isLoading } = useUserV2();
  const router = useRouter();

  if (isLoading) {
    return <AppLoadingMessage />;
  }

  if (error) {
    const returnTo = router.pathname;
    router.push(`/api/auth/logout?returnTo=${returnTo}`);

    return <></>;
  }

  return (
    <PermissionGatedContent
      permissionNeeded={permissionsNeeded}
      userPermissions={data.permissions}
    >
      <>
        {unleashFlag !== undefined ? (
          <GatedContent unleashFlag={unleashFlag}>{children}</GatedContent>
        ) : (
          children
        )}
      </>
    </PermissionGatedContent>
  );
};
