import { PermissionsEnum } from 'model/User';
import { GrantAccess } from 'components/GrantAccess';
import { Welcome } from 'screens/Welcome';
import { GetServerSideProps } from 'next';
import { getQuery } from './api/request/utils';

export default function IndexPage() {
  return (
    <GrantAccess permissionsNeeded={PermissionsEnum.RUN_RESOURCES}>
      <Welcome />
    </GrantAccess>
  );
}

export const getServerSideProps = (async ({ query }) => {
  const searchParams = getQuery(query);
  const invitation = searchParams.get('invitation');

  if (!invitation) return { props: {} };

  return {
    redirect: {
      destination: `/api/auth/login?invitation=${invitation}`,
      permanent: false,
    },
  };
}) satisfies GetServerSideProps;
