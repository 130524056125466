import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { SimpleCard } from 'components/Cards/SimpleCard';

const styles = {
  wrapper: {
    width: '100vw',
    height: 'calc(100vh - 100px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  content: {
    width: '100%',
    minWidth: 400,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    py: 4,
  },
};

const MESSAGES = [
  'Waking robots up...',
  'Communicating with mothership...',
  'Applying hyperdrives...',
  '01011110001101...',
];

export const AppLoadingMessage = (): JSX.Element => {
  const [messageIndex, setMessageIndex] = useState<number>(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessageIndex(
        messageIndex < MESSAGES.length - 1 ? messageIndex + 1 : 0
      );
    }, 750);

    return () => {
      clearTimeout(timer);
    };
  }, [messageIndex]);

  return (
    <Box sx={styles.wrapper}>
      <SimpleCard>
        <Box sx={styles.content}>
          <CircularProgress color="secondary" />
          <Box paddingTop={2}>
            <Typography variant="body2" color="textSecondary">
              {MESSAGES[messageIndex]}
            </Typography>
          </Box>
        </Box>
      </SimpleCard>
    </Box>
  );
};
