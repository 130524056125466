export enum PermissionsEnum {
  ADMIN_RESOURCES = 'admin_resources',
  RUN_RESOURCES = 'run_resources',
  RUN_OPERATIONS = 'run_operations',
  NO_ACCESS = 'no_access',
}

export enum PermissionLevelsEnum {
  WRITE = 'write',
  READ = 'read',
  EXECUTE = 'execute',
  NONE = 'none',
}

export type PermissionInfo = {
  label: string;
  description: string;
  level: PermissionLevelsEnum;
};

export type UserPermissionsData = Map<PermissionsEnum, PermissionInfo>;
