import Stack from '@mui/material/Stack';
import { ErrorDisplay, Typography } from '@automata/ui';
import { PermissionInfo } from 'model/User';
import useUserV2 from 'hooks/useUserV2';
import { Placeholder } from 'components/Placeholder';
import { SxStyles } from 'types/global';

const styles = {
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 500,
    width: '100%',
  },
} satisfies SxStyles;

interface PermissionLevelsProps {
  permission: PermissionInfo;
}

const PermissionLevel = ({
  permission,
}: PermissionLevelsProps): JSX.Element => {
  return (
    <Stack direction="row" gap={1}>
      <Typography variant="body1">{permission.label}:</Typography>
      <Typography color="primary" textTransform="capitalize">
        {permission.level}
      </Typography>
    </Stack>
  );
};

export const Welcome = (): JSX.Element => {
  const {
    data: user,
    isLoading: isUserLoading,
    error: userError,
  } = useUserV2();

  if (isUserLoading) {
    return <Placeholder copy="Loading..." />;
  }

  if (userError) {
    return (
      <ErrorDisplay
        error={userError}
        userMessage="Failed to fetch user, please try again"
      />
    );
  }

  return (
    <Stack sx={styles.container}>
      <Stack spacing={2}>
        <Typography variant="h4">Welcome, {user.userData?.name}</Typography>
        <Typography variant="body1">
          You&apos;re able to perform the following actions on the Automata Labs
          platform:
        </Typography>
        <Stack spacing={1}>
          {user.permissions !== undefined &&
            Array.from(user.permissions).map((permission) => {
              return (
                <PermissionLevel
                  permission={permission[1]}
                  key={permission[0]}
                />
              );
            })}
        </Stack>
        <Typography variant="body1">
          If these are not what you were expecting, please contact your Automata
          Labs platform administrator
        </Typography>
      </Stack>
    </Stack>
  );
};
