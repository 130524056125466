import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TypographyProps } from '@mui/material/Typography';

const styles = {
  placeholder: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

interface PlaceholderProps {
  copy: string;
  children?: React.ReactNode;
  variant?: TypographyProps['variant'];
  position?: TypographyProps['position'];
}

export const Placeholder = ({
  copy,
  children,
  variant,
  position = 'absolute',
}: PlaceholderProps): JSX.Element => {
  return (
    <Box sx={styles.placeholder} position={position}>
      <Typography variant={variant ?? 'h5'} color="textSecondary">
        {copy}
      </Typography>
      {children}
    </Box>
  );
};
