import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { SimpleCard } from 'components/Cards/SimpleCard';
import {
  PermissionLevelsEnum,
  PermissionsEnum,
  UserPermissionsData,
} from 'model/User';

export interface PermissionGatedContentProps {
  permissionNeeded: PermissionsEnum;
  userPermissions: UserPermissionsData | undefined;
  children: React.ReactNode;
}

export const PermissionGatedContent = ({
  permissionNeeded,
  userPermissions,
  children,
}: PermissionGatedContentProps): JSX.Element | null => {
  const thisPagePermission = userPermissions?.get(permissionNeeded)?.level;

  if (thisPagePermission === PermissionLevelsEnum.NONE) {
    return (
      <Container maxWidth="xs">
        <Box paddingTop={6}>
          <SimpleCard title="You don't have permission to view this page">
            <Typography>Please contact your admin</Typography>
          </SimpleCard>
        </Box>
      </Container>
    );
  }

  if (thisPagePermission === PermissionLevelsEnum.READ) {
    return (
      <>
        <Alert severity="warning">
          You only have read access to this resource. You won&apos;t be able to
          make changes
        </Alert>
        {children}
      </>
    );
  }

  return <>{children}</>;
};
